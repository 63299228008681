


























import { defineComponent, PropType, ref, watchEffect } from "@vue/composition-api";

export default defineComponent({
  name: "GenericPicker",
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const items = [
      {
        label: "Kartleggingsrapport",
        value: "firstReport",
      },
      {
        label: "Vanlig rapport",
        value: "regularReport",
      },
    ];

    return {
      emit,
      items,
      _value,
    };
  },
});
