
















































import { createReport, getPreviousReport } from "@/api/reportsService";
import { uiModule } from "@/store/modules/ui";
import { defineComponent, onMounted, PropType, ref, watchEffect } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "SendEmptyReportDialog",
  props: {
    lesson: {
      type: Object as PropType<Learnlink.Lesson.FullViewApp>,
      required: true,
    },
    studentName: {
      type: String as PropType<string>,
      required: true,
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const reportForm = {} as Learnlink.Report.default;

    const loading = ref(false);
    async function sendEmptyReport() {
      loading.value = true;
      await createReport({
        customerUID: props.lesson.customerUID,
        goalLater: reportForm.goalLater || "",
        goalToday: "",
        lessonID: props.lesson.ID,
        motivation: 50,
        motivationText: "",
        name: props.studentName,
        nextTime: "",
        notes: "",
        projectID: props.lesson.projectID,
        sellerUID: props.lesson.sellerUID || "",
      });
      await uiModule.actions.fetchIncompleteReportsCount();
      emit("success");
      _value.value = false;
      loading.value = false;
    }
    onMounted(async() => {
      loading.value = true;
      const previousReport = await getPreviousReport(props.lesson.projectID);
      if (previousReport?.goalLater) reportForm.goalLater = previousReport.goalLater;
      loading.value = false;
    });

    return {
      _value,
      loading,
      sendEmptyReport,
    };
  },
});
