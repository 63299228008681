



































import { defineComponent, PropType } from "@vue/composition-api";
import { rateLesson } from "@/api/lessonService";
import analytics from "@/services/analytics";
import Learnlink from "@learnlink/interfaces";
import Moment from "@/services/moment";

export default defineComponent({
  name: "RateLesson",
  props: {
    lesson: {
      type: Object as PropType<Learnlink.Lesson.FullViewApp>,
      required: true,
    },
  },
  setup(props) {
    const submitRating = async(rating: number) => {
      analytics.track("rateLesson", { rating: rating });
      if (rating === 1) {
        window.Intercom("showNewMessage", "Jeg opplevde problemer under timen min " + Moment.unix(props.lesson.startTime).format("DD.MM.YYYY HH:mm") + ":");
      }
      await rateLesson(props.lesson.ID, rating);
    };
    return {
      submitRating,
    };
  },
});
