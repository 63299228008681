


































































import { getAllTeamSessionsWithoutPerformanceForUser } from "@/api/teamSessionService";
import MissingReportList from "@/components/reports/views/MissingReportList.vue";
import MissingTeamReportList from "@/components/reports/views/MissingTeamReportList.vue";
import { defineComponent, onMounted, provide, reactive } from "@vue/composition-api";
import { getAllReportsForUser } from "@/api/reportsService";
import { getLessonsWithoutReport } from "@/api/lessonService";
import { userModule } from "@/store/modules/user";
import handleError from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";
import ReportList from "@/components/reports/views/ReportList.vue";

export default defineComponent({
  name: "SellerReports",
  components: {
    MissingTeamReportList,
    MissingReportList,
    ReportList,
  },
  setup() {
    const vm = reactive({
      lessonsMissingReport: [] as Learnlink.Lesson.FullViewApp[],
      teamSessionsMissingReport: [] as Learnlink.TeamSession.PerformanceAppView[],
      loading: false,
      refresh: false,
      reports: [] as Learnlink.Report.FullViewApp[],
      tab: null,
    });

    onMounted(async () => {
      try {
        vm.loading = true;
        [vm.reports, vm.lessonsMissingReport, vm.teamSessionsMissingReport] = await Promise.all([
          getAllReportsForUser(userModule.state.userId, true),
          getLessonsWithoutReport(userModule.state.userId),
          getAllTeamSessionsWithoutPerformanceForUser(userModule.state.userId),
        ]);
        vm.loading = false;
      }
      catch (e) {
        handleError(e);
      }
    });

    async function refreshAll() {
      vm.refresh = true;
      [vm.reports, vm.lessonsMissingReport, vm.teamSessionsMissingReport] = await Promise.all([
        getAllReportsForUser(userModule.state.userId, true),
        getLessonsWithoutReport(userModule.state.userId),
        getAllTeamSessionsWithoutPerformanceForUser(userModule.state.userId),
      ]);
    }

    provide("refreshAllReports", refreshAll);

    return {
      refreshAll,
      vm,
    };
  },
});
