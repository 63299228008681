

















































































































































































import { createTeamSessionReport } from "@/api/teamSessionService";
import { negativeReasons, negativeReasonsLocale, ratingSymbols, ratingText } from "@/helpers/feedbackUtils";
import rules from "@/helpers/formRules";
import { getReadableTeamSessionStartTime } from "@/helpers/timeUtils";
import { useNotifier } from "@/providers/notifier";
import { uiModule } from "@/store/modules/ui";
import { userModule } from "@/store/modules/user";
import { defineComponent, inject, onMounted, PropType, reactive, ref, watchEffect } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "TeamSessionReportForm",
  props: {
    teamSessionPerformanceAppView: {
      type: Object as PropType<Learnlink.TeamSession.PerformanceAppView>,
      required: true,
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });
    const notify = useNotifier();
    const refresh = inject<() => Promise<void>>("refreshAllReports");
    const levels = ["low", "medium", "high"] as Learnlink.Type.Level[];

    enum levelsLocale {
      "low" = "😕",
      "medium" = "🙂",
      "high" = "🥳",
    }

    const vm = reactive({
      loading: true,
      message: {} as Record<string, Learnlink.Message.Create>,
      noShow: [] as string[],
      performance: {} as Learnlink.TeamSession.PerformanceMap,
      sessionReview: {
        rating: 0,
        comment: "",
        negativeReason: "",
      } as Learnlink.TeamSession.Review,
    });

    onMounted(() => {
      vm.loading = true;
      vm.performance = props.teamSessionPerformanceAppView.students.reduce((accumulator, student) => {
        accumulator[student.uid] = {
          comment: "",
          level: "",
        };
        return accumulator;
      }, {} as Learnlink.TeamSession.PerformanceMap);
      vm.message = props.teamSessionPerformanceAppView.students.reduce((accumulator, student) => {
        accumulator[student.uid] = {
          from: userModule.state.userId,
          text: "",
          to: student.parentUID,
        };
        return accumulator;
      }, {} as Record<string, Learnlink.Message.Create>);
      vm.loading = false;
    });

    function noShow(uid: string) {
      if (!vm.noShow.includes(uid)) {
        vm.noShow.push(uid);
      }
      vm.performance[uid].level = "";
    }

    function removeFromNoShow(uid: string) {
      if (vm.noShow.includes(uid)) {
        vm.noShow.splice(vm.noShow.indexOf(uid), 1);
      }
    }

    async function submit() {
      vm.loading = true;
      const messages = Object.values(vm.message).filter(m => m.text);
      if (someStudentIsNotAccountedFor() || (messageRequired() && someMessageIsMissing())) {
        notify({
          title: "Noe mangler",
          message: "Gjør rede for samtlige elever, og gi tilbakemelding på kurset. 🤸‍",
          type: "error",
        });
      }
      else if (messages.some(message => message.text.length < 50)) {
        notify({
          title: "Melding til forelder er for kort 🤯",
          message: "Pass på at meldingene til foreldre er nøye skrevet. 🦾",
          type: "error",
        });
      }
      else if (!vm.sessionReview.comment) {
        notify({
          title: "Vi trenger din vurdering av dagens session 🤠",
          message: "Det hjelper oss veldig når vi bestemmer hvordan produktet utvikles videre.",
          type: "error",
        });
      }
      else {
        const report = {
          messages,
          noShow: vm.noShow,
          performance: vm.performance,
          sellerUID: userModule.state.userId,
          sessionReview: vm.sessionReview,
          teamSessionID: props.teamSessionPerformanceAppView.teamSessionID,
        };
        try {
          await createTeamSessionReport(report);
          await uiModule.actions.fetchIncompleteReportsCount();
          notify({
            title: "Jippi!",
            message: "Da var den rapporten i boks! Godt jobbet. 🥳",
            type: "success",
          });
          refresh && await refresh();
          _value.value = false;
        }
        catch (e) {
          notify({
            title: "Noe gikk galt 🤯",
            message: "Kontakt support dersom problemet vedvarer.",
            type: "error",
          });
        }
      }
      vm.loading = false;
    }

    function someMessageIsMissing(): boolean {
      return props.teamSessionPerformanceAppView.students.some(studentIsMissingMessage);
    }

    function studentIsMissingMessage(student: Learnlink.Student.View.App.ForTeam): boolean {
      return !vm.noShow.includes(student.uid) && !vm.message[student.uid].text;
    }

    function messageRequired(): boolean {
      return props.teamSessionPerformanceAppView.order % 4 === 0;
    }

    function someStudentIsNotAccountedFor(): boolean {
      return props.teamSessionPerformanceAppView.students.some(studentIsNotAccountedFor);
    }

    function studentIsNotAccountedFor(student: Learnlink.Student.View.App.ForTeam): boolean {
      return !vm.noShow.includes(student.uid) && !vm.performance[student.uid].level;
    }

    function setNegativeReason(reason: string): void {
      vm.sessionReview.negativeReason = reason;
    }

    return {
      _value,
      getReadableTeamSessionStartTime,
      levels,
      levelsLocale,
      negativeReasons,
      negativeReasonsLocale,
      noShow,
      ratingSymbols,
      ratingText,
      removeFromNoShow,
      setNegativeReason,
      rules,
      submit,
      vm,
    };
  },
});
