



























import { defineComponent, PropType, ref, watchEffect } from "@vue/composition-api";
import Vue from "vue";

export default defineComponent({
  name: "SixWeekPlan",
  props: {
    value: {
      type: Object,
      required: true,
    },
    submitted: {
      type: Boolean as PropType<boolean>,
      default: false,
    }
  },
  setup(props, { emit, refs }) {
    const _value = ref(props.value);

    watchEffect(() => {
      emit("input", _value);
    });

    watchEffect(() => {
      if (props.submitted === true) {
        (refs.form as Vue & { validate: () => boolean }).validate();
      }
    });

    const validForm = ref(false);
    watchEffect(() => {
      if (validForm) emit("valid");
    });

    const formRules = {
      required: (v: string) => !!v || "Obligatorisk felt",
    };
    return {
      _value,
      formRules,
      validForm,
    };
  },
});
