















































import TeamSessionReportForm from "@/components/reports/forms/TeamSessionReportForm.vue";
import { getReadableTeamSessionStartTime } from "@/helpers/timeUtils";
import { defineComponent, PropType, reactive } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "MissingTeamReportList",
  components: { TeamSessionReportForm },
  props: {
    teamSessionsMissingReport: {
      type: Array as PropType<Learnlink.TeamSession.PerformanceAppView[]>,
      required: true,
    },
  },
  setup() {
    const vm = reactive({
      teamSessionReportFormVisible: false,
      teamSession: {} as Learnlink.TeamSession.PerformanceAppView,
    });

    function openReportForm(session: Learnlink.TeamSession.PerformanceAppView): void {
      vm.teamSession = session;
      vm.teamSessionReportFormVisible = true;
    }

    function getReportType(session: Learnlink.TeamSession.PerformanceAppView): string {
      return session.order % 4 === 0 ? "Månedsrapport" : "Rapport";
    }

    return {
      getReadableTeamSessionStartTime,
      getReportType,
      openReportForm,
      vm,
    };
  }
});
