


































































































import { getFirstLesson } from "@/api/lessonService";
import FirstLessonReportForm from "@/components/reports/forms/FirstLessonReportForm.vue";
import RegularReportForm from "@/components/reports/forms/RegularReportForm.vue";
import SendEmptyReportDialog from "@/components/reports/SendEmptyReportDialog.vue";
import ReportTypePicker from "@/components/reports/ReportTypePicker.vue";
import Moment from "@/services/moment";
import Learnlink from "@learnlink/interfaces";
import { defineComponent, PropType, reactive } from "@vue/composition-api";

export default defineComponent({
  name: "MissingReportList",
  components: {
    SendEmptyReportDialog,
    FirstLessonReportForm,
    RegularReportForm,
    ReportTypePicker,
  },
  props: {
    lessonsMissingReport: {
      type: Array as PropType<Learnlink.Lesson.FullViewApp[]>,
      required: true,
    },
    reports: {
      type: Array as PropType<Learnlink.Report.FullViewApp[]>,
      required: true,
    },
  },
  setup(_, { emit }) {
    const vm = reactive({
      changeReportTypeDialogVisible: false,
      lesson: {} as Learnlink.Lesson.FullViewApp,
      loading: false,
      reportFormVisible: false,
      reportType: "",
      sendEmptyReportDialogVisible: false,
      studentName: "",
    });

    function sendEmptyReport(lesson: Learnlink.Lesson.FullViewApp): void {
      vm.studentName = lesson.project?.studentName ? lesson.project?.studentName : "eleven";
      vm.lesson = lesson;
      vm.sendEmptyReportDialogVisible = true;
    }

    async function changeReportType(reportType: string): Promise<void> {
      vm.reportType = reportType;
      vm.changeReportTypeDialogVisible = false;
      await openReportForm(vm.lesson);
    }

    function openChangeReportTypeDialog(lesson: Learnlink.Lesson.FullViewApp) {
      vm.lesson = lesson;
      vm.changeReportTypeDialogVisible = true;
    }

    async function openReportForm(lesson: Learnlink.Lesson.FullViewApp): Promise<void> {
      vm.loading = true;
      if (!vm.reportType) await calculateReportType(lesson);
      vm.studentName = lesson.project?.studentName ? lesson.project?.studentName : "eleven";
      vm.lesson = lesson;
      vm.reportFormVisible = true;
      vm.loading = false;
    }

    async function calculateReportType(lesson: Learnlink.Lesson.FullViewApp) {
      const firstLesson = await getFirstLesson(lesson.projectID);

      if (firstLesson.ID === lesson.ID) {
        vm.reportType = "firstReport";
      }
      else {
        vm.reportType = "regularReport";
      }
    }

    function getStudentName(lesson: Learnlink.Lesson.FullViewApp): string {
      return lesson.project && lesson.project.studentName ? lesson.project.studentName : "";
    }

    function getReadableDate(lesson: Learnlink.Lesson.FullViewApp): string {
      return `${Moment.unix(lesson.startTime).format("LLLL")} – ${Moment.unix(lesson.endTime).format("HH:mm")}`;
    }

    function refresh(): void {
      vm.reportType = "";
      emit("refresh");
    }

    return {
      changeReportType,
      getReadableDate,
      getStudentName,
      openChangeReportTypeDialog,
      openReportForm,
      refresh,
      sendEmptyReport,
      vm,
    };
  },
});
