














































import WeeksPlan from "@/components/reports/views/WeeksPlan.vue";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import Moment from "@/services/moment";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "FirstReportView",
  components: { WeeksPlan },
  props: {
    report: {
      type: Object as PropType<Learnlink.Report.FullViewApp>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const closeForm = () => emit("close");
    const readableDate = computed(() => `${Moment.unix(props.report.lesson?.startTime || 0).format("LLLL")} – ${Moment.unix(props.report.lesson?.endTime || 0).format("HH:mm")}`);
    const sellerName = computed(() => `${props.report.seller?.profile.firstName} ${props.report.seller?.profile.lastName}`);
    const weeks = {
      week1: props.report.week1,
      week2: props.report.week2,
      week3: props.report.week3,
      week4: props.report.week4,
      week5: props.report.week5,
      week6: props.report.week6,
    };
    return {
      closeForm,
      readableDate,
      sellerName,
      weeks,
    };
  },
});
